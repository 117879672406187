/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-content-attribute-update-item-wrapper {
  display: flex;
}
.altai-theme-admin .altai-admin-section {
  padding: 10px 20px;
}
.altai-theme-admin .altai-admin-section .header {
  padding: 0 10px;
}
.altai-theme-admin .altai-admin-section .header h1 {
  font-size: 20px;
}
.altai-theme-admin .altai-admin-section .actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.altai-theme-admin .altai-admin-section table {
  margin: 20px 0 20px 0;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 4px solid #eee;
  width: 100%;
  background-color: #f3f3f3;
}
.altai-theme-admin .altai-admin-section table select,
.altai-theme-admin .altai-admin-section table textarea,
.altai-theme-admin .altai-admin-section table input {
  font-size: 100%;
  border: 1px solid #f1f1f1;
}
.altai-theme-admin .altai-admin-section table input {
  padding: 5px;
}
.altai-theme-admin .altai-admin-section table thead {
  font-weight: bold;
}
.altai-theme-admin .altai-admin-section table thead .checkbox {
  width: 1%;
}
.altai-theme-admin .altai-admin-section table td {
  padding: 4px 10px 4px 10px;
  text-align: left;
  font-weight: 100;
  font-size: small;
}
.altai-theme-admin .altai-admin-section table th {
  padding: 10px;
  text-align: left;
  font-weight: 100;
  font-size: small;
  font-style: italic;
}
.altai-theme-admin .altai-admin-section table tr:nth-child(odd) {
  background-color: #f3f3f3;
}
.altai-theme-admin .altai-admin-section table tr.accompanyingperson {
  font-style: italic;
  color: #404040;
}
.altai-theme-admin .altai-admin-section table tr.accompanyingperson:hover {
  background-color: initial;
}
.altai-theme-admin .altai-admin-section table tr.optout {
  font-style: italic;
  color: #5c5c5c;
}
.altai-theme-admin .altai-admin-section table tr.optout .md-button-altai {
  background-color: lightgray;
  color: gray;
}
.altai-theme-admin .altai-admin-section table tbody tr:hover {
  background-color: #dbdbdb;
}
.altai-theme-admin .altai-admin-section table .actioncell {
  min-width: 80px;
}
.altai-theme-admin .altai-admin-section form {
  border: 0px solid #eee;
  width: 100%;
}
.altai-theme-admin .altai-admin-section form .form-row {
  display: flex;
  padding: 10px;
  align-items: center;
}
.altai-theme-admin .altai-admin-section form .form-row .label {
  width: 200px;
}
.altai-theme-admin .altai-admin-section form .form-row input {
  padding: 5px;
  min-width: 250px;
}
.altai-theme-admin .altai-admin-section form .form-row:nth-child(even) {
  background-color: #efefef;
}
